export const ClientsListTableID = 'clients-list'
export const AnalyticsListTableID = 'analytics-list'
export const CurrenciesListTableID = 'currencies-list'
export const CustomCurrenciesListTableID = 'custom-currencies-list'
export const CustomCurrenciesAliasListTableID = 'custom-currencies-alias-list'
export const GamesListTableID = 'games-list'
export const HitsListTableID = 'hits-list'
export const JackpotsListTableID = 'jackpots-list'
export const LevelsListTableID = 'levels-list'
export const LocationsListTableID = 'locations-list'
export const LogsListTableID = 'logs-list'
export const PaymentsListTableID = 'payments-list'
export const ProductsListTableID = 'products-list'
export const SystemsListTableID = 'systems-list'
export const TeamsListTableID = 'teams-list'
export const TemplateListTableID = 'template-list'
export const UsersListTableID = 'users-list'
export const IntegrantsListTableID = 'integrants-list'
export const ServiceInvoicesListTableID = 'service-invoices-list'
export const PrizesListTableID = 'hit-prizes-leaderboard-list'

export const ProductAccessClientSelectFromListTableID = 'product-access-client-select-from-list'
export const ProductAccessClientSelectToListTableID = 'product-access-client-select-to-list'
export const TeamDetailPlayersListTableID = 'team-detail-players-list'
export const UserDetailsControlledCompaniesListTableID = 'user-detail-controlled-companies-list'
export const LocationsSelectTableID = 'locations-select'
export const LocationTypeSelectTableID = 'location-type-select'
export const GamesSelectListTableID = 'games-select'
export const GamesCategoriesSelectListTableID = 'games-categories-select-list'
export const CurrenciesSelectListTableID = 'currencies-select-list'
export const ActionsSelectListTableID = 'actions-select-list'
export const AgreementsSelectListTableID = 'agreements-select-list'
export const ClientsSelectListTableID = 'clients-select-list'
export const ProductTypesSelectListTableID = 'product-types-select-list'
export const ServicesSelectListTableID = 'services-select-list'
export const ProductsSelectListTableID = 'products-select-list'
