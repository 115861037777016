import { isValid } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { Decimal } from 'decimal.js'
import { T, identity, isNil } from 'ramda'
import * as yup from 'yup'
import { addMethod, mixed, number, setLocale, string } from 'yup'
import {
  ComponentConfigAccessType,
  type LevelSchedulerActivitySpan,
} from '~/src/__generated__/graphql'
import { JSAMainUserName, MAX_INTEGER } from '~/src/constants'
import {
  validateExclusiveSpan,
  validateInclusiveSpan,
} from '~/src/helpers/jackpots/scheduler/span-validation'
import { ValidationErrorKeyNumberMinAsString } from './constant'
import {
  activitySpansOverlap,
  clientCanOwnPrivateAccessOnly,
  dateMaxUTCError,
  dateMinFinishDateError,
  dateMinUTCError,
  emailError,
  minLengthIntervalError,
  notNumberTypeError,
  numberMaxError,
  numberMaxMaskError,
  numberMinError,
  numberMinMaskError,
} from './yup-custom-errors'
import { yupLocale } from './yup-locale'

export function yupInitialize() {
  setLocale(yupLocale)

  addMethod(mixed, 'min', function min(minCompare: string) {
    return this.transform(identity).test(
      'Custom mixed min validation',
      numberMinError(minCompare),
      (number: Decimal.Value): boolean => {
        if (isNil(number)) {
          return true
        }

        if (!yup.number().validateSync(number)) {
          return true
        }

        return new Decimal(number).greaterThanOrEqualTo(minCompare)
      },
    )
  })

  addMethod(mixed, 'max', function max(maxCompare: string) {
    return this.transform(identity).test(
      'Custom mixed max validation',
      numberMaxError(maxCompare),
      (number: Decimal.Value): boolean => {
        if (isNil(number)) {
          return true
        }

        if (!yup.number().validateSync(number)) {
          return true
        }

        return new Decimal(number).lessThanOrEqualTo(maxCompare)
      },
    )
  })

  addMethod(number, 'minMask', function minMask(min: number, mask: string) {
    return this.test((value: number, context) => {
      const resolvedMin: number = context.resolve<number>(min)
      return this.min(resolvedMin)
        .validate(value)
        .then(T)
        .catch(() => {
          return context.createError({
            message: numberMinMaskError(mask, resolvedMin),
            path: context.path,
          })
        })
    })
  })

  addMethod(string, 'clientCanOwnPrivateAccessOnly', function clientCanOwnPrivateAccessOnlyCheck() {
    return this.test('clientCanOwnPrivateAccessOnly', (access: string, context) => {
      const parent = context.parent || context.options?.context?.parent
      const clientName = parent?.clientName

      if (access === ComponentConfigAccessType.Private) {
        return true
      }

      if (clientName === JSAMainUserName) {
        return true
      }

      return context.createError({
        message: clientCanOwnPrivateAccessOnly(clientName),
        path: context.path,
      })
    })
  })

  addMethod(number, 'maxMask', function maxMask(max: number, mask: string) {
    return this.test((value: number, context) => {
      const resolvedMax: number = context.resolve<number>(max)
      return this.max(resolvedMax)
        .validate(value)
        .then(T)
        .catch((e) => {
          // Hate this. Leave for weekend to dig deeper into this
          // Why do we catch minMask errors here? We're using this.max, WTH?
          if (e.message.key === ValidationErrorKeyNumberMinAsString) {
            return true
          }

          return context.createError({
            message: numberMaxMaskError(mask, resolvedMax),
            path: context.path,
          })
        })
    })
  })

  addMethod(yup.date, 'dateInFuture', function dateInFuture() {
    return this.test((value, context) => {
      const now = new Date()
      return this.min(now)
        .validate(value)
        .then(T)
        .catch(() =>
          context.createError({
            message: 'Date should not be in past',
            path: context.path,
          }),
        )
    })
  })

  addMethod(yup.date, 'minUTC', function minUTC(against) {
    return this.test((value, context) => {
      const parent = context.parent || context.options?.context?.parent
      const againstCompare = typeof against === 'object' ? parent?.[against.key] : against

      if (!value || !isValid(value)) {
        return true
      }

      if (againstCompare < value) {
        return true
      }

      const dateCompare = toZonedTime(parent?.[against.key], 'UTC')

      return context.createError({
        message: dateCompare && isValid(dateCompare) ? dateMinUTCError(dateCompare) : '',
        path: context.path,
      })
    })
  })

  addMethod(yup.date, 'levelSchedulerFinishDate', function levelSchedulerFinishDate(against) {
    return this.test((value, context) => {
      if (!value || !isValid(value)) {
        return true
      }

      return this.minUTC(against)
        .validate(toZonedTime(value, 'UTC'), {
          context,
        })
        .then(T)
        .catch(() =>
          context.createError({
            message: dateMinFinishDateError,
            path: context.path,
          }),
        )
    })
  })

  addMethod(yup.date, 'maxUTC', function maxUTC(against) {
    return this.test((value, context) => {
      const parent = context.parent || context.options?.context?.parent
      const againstCompare = typeof against === 'object' ? parent?.[against.key] : against

      const dateCompare = toZonedTime(parent?.[against.key], 'UTC')

      return this.max(againstCompare)
        .validate(value)
        .then(T)
        .catch(() =>
          context.createError({
            message: dateCompare && isValid(dateCompare) ? dateMaxUTCError(dateCompare) : '',
            path: context.path,
          }),
        )
    })
  })

  addMethod(yup.string, 'email', function validateEmail() {
    /**
     * Completely stolen from backend guys (golang validation lib) with minor changes
     * x{00A0}-x{D7FF} -> x{00A0}\-x{D7FF} - dash escaped for javascript
     * No escape for spec chars like *, & and so on.
     */
    const myEmailRegex =
      /^(((([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])+(\.([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])+)*)|((x22)((((x20|x09)*(x0dx0a))?(x20|x09)+)?(([x01-x08x0bx0cx0e-x1fx7f]|x21|[x23-x5b]|[x5d-x7e]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])|(\([x01-x09x0bx0cx0d-x7f]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}]))))*(((x20|x09)*(x0dx0a))?(x20|x09)+)?(x22)))@((([a-zA-Z]|\d|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])|(([a-zA-Z]|\d|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])([a-zA-Z]|\d|-|\.|_|~|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])*([a-zA-Z]|\d|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])))\.)+(([a-zA-Z]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])|(([a-zA-Z]|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])([a-zA-Z]|\d|-|_|~|[x{00A0}\-x{D7FF}x{F900}\-x{FDCF}x{FDF0}\-x{FFEF}])*([a-zA-Z]|[x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])))\.?$/

    return this.matches(myEmailRegex, {
      message: emailError,
      excludeEmptyString: true,
    })
  })

  addMethod(
    mixed,
    'activitySpansLevels',
    function validateActivityLevelSpans({ overlapErrorPath, numberInvalidErrorPath }) {
      return this.test((activitySpans, context) => {
        const levelIndex = Number.parseInt(context.path?.split('[')[1]?.split(']')[0] ?? '0', 10)
        const isValidOrError = activitySpans?.reduce(
          (
            isValid: boolean,
            spanValidate: LevelSchedulerActivitySpan,
            index: number,
            spans: LevelSchedulerActivitySpan[],
          ) => {
            if (isValid) {
              const overlapError = context.createError({
                message: activitySpansOverlap,
                path: overlapErrorPath(levelIndex),
              })

              const zeroLengthError = context.createError({
                message: minLengthIntervalError,
                path: overlapErrorPath(levelIndex),
              })

              const { startSec, endSec, hitLimit } = spanValidate

              if (startSec === endSec) {
                return zeroLengthError
              }

              if (!isNil(hitLimit) && !Number.isNaN(hitLimit)) {
                // Need to verify this manually as activitySpans are in mixed schema and validated through transform
                if (!yup.number().integer().isValidSync(hitLimit)) {
                  return context.createError({
                    message: notNumberTypeError,
                    path: numberInvalidErrorPath(levelIndex, index),
                  })
                }

                if (!yup.number().min(0).isValidSync(hitLimit)) {
                  return context.createError({
                    message: numberMinError(0),
                    path: numberInvalidErrorPath(levelIndex, index),
                  })
                }

                if (!yup.number().max(MAX_INTEGER).isValidSync(hitLimit)) {
                  return context.createError({
                    message: numberMaxError(MAX_INTEGER),
                    path: numberInvalidErrorPath(levelIndex, index),
                  })
                }
              }

              /**
               * See original code in
               * backend/application/timekit/timeline/timeline.go
               * timeline.hasSpansInRange function
               */
              if (endSec > startSec) {
                const filterSpans = validateExclusiveSpan(spans, index, spanValidate)

                if (filterSpans.length > 0) {
                  return overlapError
                }
              } else {
                const filterSpans = validateInclusiveSpan(spans, index, spanValidate)

                if (filterSpans.length > 0) {
                  return overlapError
                }
              }
            }

            return isValid
          },
          true,
        )

        return isValidOrError
      })
    },
  )
}
